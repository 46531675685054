import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { CosmosTable } from '../models/cosmos/cosmos-table';
import { CosmosTableRow } from '../models/cosmos/cosmos-table-row';
import { CosmosTableSchema } from '../models/cosmos/cosmos-table-schema';
import { TableData } from '../models/table-data';
import { TenantService } from './tenant.service';
import { AuthService } from './auth-service.service';
import { ConfigService } from './config.service';
import { myTableChanges } from '../models/my-table-changes';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  public $tables: BehaviorSubject<CosmosTable[]> = new BehaviorSubject<CosmosTable[]>(null);
  private tables: CosmosTable[] = [];
  private tableDataSets: TableData[] = [];
  public selectedTable;
  private clientNameSpace;
  private baseUrl: string;
  private page: number = 1;
  private pageCount: number = 25;
  public totalPages: number = 0;
  public rowChanges: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public rowChangesToDelete: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public rowChangesToCopy: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  myTableChanges: myTableChanges;

  constructor(public tenantService: TenantService, private http: HttpClient, private authService: AuthService, private configService: ConfigService) {
    this.configService.$configuration.subscribe(config => {
      if (config != null) {
        this.baseUrl = config.servicesUrl;
      }
    });
    this.authService.$userClaims.subscribe(claims => {
      if (claims != null && claims.length > 0) {
        var clientNameSpace = claims.find(claim => claim.type == "df.ns").value;
        this.clientNameSpace = clientNameSpace;
      }
    });
  }

  public async loadTables() {
    var schemaIds = [];
    var schemaIdCheck = {};

    if (this.tableDataSets.length == 0) {
      await this.loadTableDataSets();
    }
    this.tables = this.tableDataSets.map(t => new CosmosTable(t));
    this.tables.forEach(t => {
      if (!schemaIdCheck[t.schemaId]) {
        schemaIds.push(t.schemaId);
        schemaIdCheck[t.schemaId] = true;
      }
    });
    this.tables = this.tables.sort(this.tableSortFunction);
    this.$tables.next(this.tables);
  }

  public selectTable(tableId: string) {
    if (this.tables != null) {
      this.selectedTable = this.tables.find(t => t.id == tableId);
    }
  }

  private async loadTableDataSets(nameSpaceOverride: string = null) {
    var tableIdsUrl = `${this.baseUrl}/api/${nameSpaceOverride != null ? nameSpaceOverride : this.clientNameSpace}/TableData`;
    await this.http.get<TableData[]>(tableIdsUrl).toPromise().then(async tables => {
      this.tableDataSets = tables
    })
      .catch(e => {
        console.error(e);
      });
  }

  public async getTableDataSets(nameSpaceOverride: string = null): Promise<TableData[]> {
    if (this.tableDataSets.length == 0) {
      await this.loadTableDataSets(nameSpaceOverride);
    }
    return this.tableDataSets;
  }

  tableSortFunction = (a, b) => {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (!a.order && !b.order) {
      // Both orders values are null - sort by alpha
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    else if ((a.order != null && !b.order) || (!a.order && b.order != null)) {
      // One order value is null, assign priority to non-null value
      if (!a.order) {
        return 1;
      } else {
        return -1;
      }
    }
    else if (a.order == b.order) {
      // Both order values are equal, sort by alpha
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    }
    else {
      if (a.order < b.order) {
        return -1;
      }
      if (a.order > b.order) {
        return 1;
      }
    }
    return 0;
  };

  public async loadSelectedTable() {
    if (this.selectedTable != null) {
      // ---LOAD SCHEMA---
      await this.loadTableSchema(this.selectedTable.schemaId);

      // ---LOAD ROWS---
      //Load first page of rows with size 25
      const tableData = await this.loadTablePage(this.selectedTable.id, this.page, this.pageCount, null, null);
      this.updateTableData(tableData);
    }
  }
  public async loadTableSchema(schemaId: string) {
    //Build URL to load schema
    var schemasUrl = `${this.baseUrl}/api/${this.clientNameSpace}/TableSchema/${schemaId}`;
    //HTTP GET schema
    await this.http.get<CosmosTableSchema>(schemasUrl).toPromise().then(schema => {
      this.selectedTable.schema = schema
    }).catch(e => {
      console.error(e)
    });
  }

  public updateTableData(tableData: TableData) {
    this.selectedTable.data.id = tableData.id;
    this.selectedTable.data.itemType = tableData.id;
    this.selectedTable.data.name = tableData.name;
    this.selectedTable.data.schemaId = tableData.schemaId;
    this.selectedTable.data.partitionKey = tableData.partitionKey;
    this.selectedTable.data.rows = [];
    tableData.rows.forEach(row => {
      var rowData = new CosmosTableRow();
      rowData.currentValue = row;
      rowData.initialValue = row;
      this.selectedTable.data.rows.push(rowData);
    });
  }
  public addTableRow(rowData: any, tableId: string) {
    var table = this.tables.find(t => t.id == tableId);
    var newRow = new CosmosTableRow();
    newRow.currentValue = rowData;
    table.data.rows.push(newRow);
  }
  public addCopyTableRow(rowData: any, tableId: string) {
    var table = this.tables.find(t => t.id == tableId);
    var newRow = new CosmosTableRow();
    newRow.currentValue = rowData;
    newRow.initialValue = rowData;
    this.myTableChanges.editRow(newRow.currentValue.id, newRow.currentValue);
    table.data.rows.push(newRow);
  }

  public editRow(rowData: any, tableId: string, page: number) {
    this.myTableChanges.editRow(rowData.data.id, rowData.data);
  }


  public getInitialValues(rowId: string, tableId: string) {
    var row = this.myTableChanges.editedRows.getRow(rowId);
    return row;
  }


  public async importTableRowsFromFile(rows: {}[], tableId: string): Promise<boolean> {
    var ret = false;
    var table = this.tables.find(t => t.id == tableId);
    var body = {};
    var schema: CosmosTableSchema = table.schema
    body["id"] = table.data.id;
    body["schemaId"] = table.data.schemaId;
    body["name"] = table.data.name;
    body["rows"] = rows;
    var json = JSON.stringify(body);
    var upsertUrl = `${this.baseUrl}/api/${this.tenantService.$tenant.value.id}/${tableId}`;
    await this.http.put<TableData>(upsertUrl, body).toPromise().then(tableData => {
      // update $tables
      rows.forEach(updatedRow => {
      });
      ret = true;
    }).catch(e => {
      return false;
    });
    return ret;
  }

  public async updateTableRows(tableDataId: string): Promise<boolean> {
    var ret = false;
    var table = this.tables.find(t => t.id == tableDataId);
    var body = {};
    var schema: CosmosTableSchema = table.schema
    body["id"] = table.data.id;
    body["partitionKey"] = table.data.partitionKey;
    body["schemaId"] = table.data.schemaId;
    body["name"] = table.data.name;
    body["rows"] = [];

    this.myTableChanges.editedRows.rows.currentChanges.forEach(row => {
      if (!row._row || !row._row.data || !row._row.data.values) {
        row['partitionKey'] = `${table.data.partitionKey}|${body["id"]}`;
        body["rows"].push(row);
        body["rows"] = body["rows"].map(row => {
          const { index, ...rowWithoutIndex } = row;
          return rowWithoutIndex;
        });
        return;
      }
      var updatedRow = {};
      schema.columns.forEach(column => {
        if (row._row.data.values[column.columnName]) {
          updatedRow[column.columnName] = row._row.data.values[column.columnName];
          updatedRow["rowState"] = row._row.data.values["rowState"];
          updatedRow["id"] = row._row.data.values["id"];
          updatedRow["partitionKey"] = `${body["partitionKey"]}|${body["id"]}`;
        }
      });
      body["rows"].push(updatedRow);
    });

    var json = JSON.stringify(body);

    var upsertUrl = `${this.baseUrl}/api/${this.clientNameSpace}/TableData/${tableDataId}`;
    await this.http.put<TableData>(upsertUrl, body).toPromise().then(tableData => {
      this.myTableChanges.editedRows.rows.currentChanges.forEach(updatedRow => {
        if (updatedRow.rowState == 2 && updatedRow._row && updatedRow._row.data) {
          var newRow = new CosmosTableRow();
          schema.columns.forEach(column => {
            newRow.currentValue[column.columnName] = updatedRow._row.data[column.columnName];
            newRow.initialValue[column.columnName] = updatedRow._row.data[column.columnName];
            table.data.rows.push(newRow);
          });
        }
      });
      ret = true;
    }).catch(error => {
      console.error('Error during HTTP request:', error);
      return Promise.reject(error);
    });
    this.myTableChanges.editedRows.rows.originalChange.clear();
    this.myTableChanges.editedRows.rows.previousChanges.clear();
    this.myTableChanges.editedRows.rows.currentChanges.clear();
    this.addRowToRowChangesToCopy(null, null);
    this.rowChanges.next([]);
    return ret;
  }

  public async loadTablePage(tableId: string, page: number, size: number, filter: string, sort: string) {
    // Set page and page count
    this.page = page;
    this.pageCount = size;
    // Create URL parameters
    const params = new URLSearchParams({
      page: `${page}`,
      size: `${size}`,
      filter: filter || '',
      sort: sort || ''
    });

    // Construct URLs for API calls
    const totalPageCountUrl = `${this.baseUrl}/api/${this.clientNameSpace}/TableData/${tableId}/count?pages?${params.toString()}`
    const tableIdsUrl = `${this.baseUrl}/api/${this.clientNameSpace}/TableData/${tableId}/pages?${params.toString()}`;
    try {
      // Fetch total page count and calculate total pages
      const totalPageCount = await this.http.get<number>(totalPageCountUrl).toPromise();
      this.totalPages = Math.ceil(totalPageCount / this.pageCount);

      // Fetch table data
      const tableData = await this.http.get<TableData>(tableIdsUrl).toPromise();

      if (this.myTableChanges && this.myTableChanges.editedRows && this.myTableChanges.editedRows.rows) {
        const editedRowMap = this.myTableChanges.editedRows.rows;
        editedRowMap.currentChanges.forEach((value, key) => {
          const index = tableData.rows.findIndex(row => row.id === key);
          if (index !== -1) {
            Object.assign(tableData.rows[index], value);
          }
          else if (value.rowState === 2) {
            tableData.rows.push(value);
          }
        });
      }
      // Log and return table data
      return tableData;
    } catch (error) {
      // Log any errors
      console.error(`Failed to load table page: ${error}`);
    }
  }

  async downloadFile(tableId: string, alias: string, filterParams: string, currentCulture: string) {
    try {
      // Encode the filterParams
      const encodedFilterParams = encodeURIComponent(filterParams);
  
      // Construct the URL with the encoded filterParams
      const allRowsCsv = `${this.baseUrl}/api/${this.clientNameSpace}/TableData/${tableId}/csv?filter=${encodedFilterParams}`;
      var fileBlob: Blob = null;
  
      await this.http.get(allRowsCsv, {
        responseType: 'blob',
        headers: {
          'Current-Culture': currentCulture
        }
      }).toPromise()
      .then(c => { fileBlob = c })
      .catch(e => { console.log(`Error retrieving IO File from url ${allRowsCsv}. Exception:${e}`) });
      return fileBlob;
    } catch (error) {
      console.error(`Failed to download file: ${error}`);
    }
  }

  
  removeRowChange(rows: any[]) {
    if (!Array.isArray(rows)) {
      rows = [rows];
    }
    const currentRowChanges = this.rowChanges.value;
    const remainingRowChanges = currentRowChanges.filter(currentRow =>
      !rows.some(row => row && row._row && row._row.data && row._row.data.id === currentRow._row.data.id)
    );
    this.rowChanges.next(remainingRowChanges);
  }
  removeRowById(id: string | number) {
    const currentRowChanges = this.rowChanges.getValue();
    const remainingRowChanges = currentRowChanges.filter(row => row._row.data.id !== id);
    this.rowChanges.next(remainingRowChanges);
  }
  //Add row to rowChanges
  addRowChange(newRow: any) {
    let currentRowChanges = this.rowChanges.value;
    currentRowChanges = currentRowChanges.filter(rowChange => rowChange !== null);
    const isUnique = !currentRowChanges.some(existingRow =>
      existingRow && existingRow._row.data.id === newRow._row.data.id);
    if (isUnique) {
      this.rowChanges.next([...currentRowChanges, newRow]);
    }
  }

  addRowToRowChangesToDelete(rows: any[], tableId: string) {
    // If rows is an object, convert it to an array
    if (!Array.isArray(rows)) {
      rows = [rows];
    }
    const currentRowChanges = this.rowChangesToDelete.value;
    // Filter out rows that are already present in rowChanges
    const uniqueRows = rows.filter(row =>
      !currentRowChanges.some(existingRow => existingRow._row.data.id === row._row.data.id)
    );
    const newRowChanges = [...currentRowChanges, ...uniqueRows];
    this.rowChangesToDelete.next(newRowChanges);
    rows.forEach(row => {
      if (row && row._row && row._row.data) {
        row._row.data.rowState = 4;
      }
    });
    // Add rows to myTableChanges
    rows.forEach(row => {
      if (row) { // Check if row is not null or undefined
        this.myTableChanges.editRow(row._row.data.id, row._row.data);
      }
    });
  }

  addRowToRowChangesToCopy(rows, tableId: string) {
    // If rows is an object, convert it to an array
    if (!Array.isArray(rows)) {
      rows = [rows];
    }
    const currentRowChanges = this.rowChangesToCopy.value;
    const newRowChanges = [...currentRowChanges, ...rows];
    this.rowChangesToCopy.next(newRowChanges);

    rows.forEach(row => {
      if (row) { // Check if row is not null or undefined
        this.myTableChanges.editRow(row._row.data.id, row._row.data);
      }
    });
  }

  removeRowToRowChangesToCopy(rows: any[]) {
    if (!Array.isArray(rows)) {
      rows = [rows];
    }

    const currentRowChanges = this.rowChangesToCopy.value;
    // Enhanced check: Ensure row, row._row, and existingRow._row are not null or undefined before comparing IDs
    const uniqueRows = rows.filter(row =>
      row && row._row && currentRowChanges.every(existingRow =>
        existingRow._row && existingRow._row.data && row._row.data && existingRow._row.data.id !== row._row.data.id)
    );
    const newRowChanges = [...currentRowChanges, ...uniqueRows];
    this.rowChangesToCopy.next(newRowChanges);
  }
}
