import { Component, Input, OnChanges } from '@angular/core';
import * as moment from 'moment'
import Tabulator from 'tabulator-tables';
import { TableSchema } from '../../models/table-schema';
import { TableService } from '../../services/table.service';
import { ModalService } from '../../modal'
import { TenantService } from '../../services/tenant.service';
import { ToastService } from 'src/app/services/toast.service';
import { BehaviorSubject } from 'rxjs';
import { DisplayService } from 'src/app/services/display.service';
import { MyAccess } from 'src/app/models/my-access';
import { AdminService } from 'src/app/services/admin.service';
import { StringLocalizerService } from 'src/app/services/string-localizer.service';
import { myTableChanges } from 'src/app/models/my-table-changes';
import { Parser } from 'src/app/helpers/parser';
import { FilterStateService } from '../../models/filter-states';

@Component({
  selector: 'app-tabulator-user-table',
  templateUrl: './tabulator-user-table.component.html',
  styleUrls: ['./tabulator-user-table.component.css'],
})
export class TabulatorUserTableComponent implements OnChanges {
  @Input() tableInfo: any;
  files: any[] = [];
  schema: TableSchema;
  tab: HTMLDivElement = null;
  tabHeaders: any[] = [];
  tabRows: any[] = [];
  table: Tabulator;
  addRowShow: boolean = true;
  duplicateSelectedRowsShow: boolean = true;
  deleteSelectedRowsShow: boolean = true;
  undeleteSelectedRowsShow: boolean = true;
  editSelectedRowsShow: boolean = true;
  saveShow: boolean = true;
  public tableIdX = new BehaviorSubject<string>("");
  public $tableIdX = this.tableIdX.asObservable();
  public drawn: boolean = false;
  public tableId: boolean = false;
  public rowStateCounts = new BehaviorSubject<{ edited: number; new: number; deleted: number }>({ edited: 0, new: 0, deleted: 0 });
  $rowChangesLength: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public tableGrayout: boolean = false;
  public showOriginal: boolean = true;
  public showChanged: boolean = true;
  public showNew: boolean = true;
  public showDeleted: boolean = false;
  public showNewDeleted: boolean = false;
  public showSelectedRecords: boolean = false;
  public showOriginalToolTipKey: string = "hideOriginalRecords";
  public showChangedToolTipKey: string = "hideEditedOriginalRecords";
  public showNewToolTipKey: string = "hideNewRecords";
  public showDeletedToolTipKey: string = "showDeletedRecords";
  public showNewDeletedToolTipKey: string = "showDeletedNewRecords";
  public allowEdit: boolean = false;
  public myAccess: MyAccess = null;
  public currentPage: number = 1;
  public pageSize: number = 25;
  public totalPages = 0;
  private filterStateService: FilterStateService;
  public sorter = '';
  public headerFilterValues = [];
  public rowChanges = [];
  public filterParams = "";
  public strings: {} = {};
  public sortParam = "";
  public tabulatorLangObject: {} = {};
  filterIsActive: boolean = false;
  constructor(
    private toastService: ToastService,
    private tableService: TableService,
    private modalService: ModalService,
    private tenantService: TenantService,
    private displayService: DisplayService,
    private adminService: AdminService,
    private stringLocalizerService: StringLocalizerService) {
    window.moment = moment;
    this.stringLocalizerService.strings.subscribe(s => {
      if (s) {
        this.strings = this.stringLocalizerService.getSection("tables");
        this.tabulatorLangObject = this.stringLocalizerService.getTabulatorLanguageObject();
      }
    })
    this.tableService.rowChanges.subscribe((rows: any[]) => {
      // Update rowChangesLength with the length of the current array
      this.$rowChangesLength.next(rows.length);
      if (rows.length > 0) {
        this.showSelectedRecords = true;
      } else {
        this.showSelectedRecords = false;
      }
    });
    this.filterStateService = new FilterStateService(); // Instantiate the service
  }
  // Utility method to check if any filter is active
  checkIfAnyFilterIsActive(): boolean {
    const filterState = this.filterStateService.getFilterState();
    const isSorterApplied = this.filterStateService.isSorterApplied();
    return Object.values(filterState).some(value => value !== null && value !== '') || isSorterApplied;
  }

  async ngOnChanges() {
    // start on intial load
    if (!this.tableService.myTableChanges) {
      this.tableService.myTableChanges = new myTableChanges(this.tableInfo["id"]);
    }
    this.currentPage = 1;
    this.totalPages = this.tableService.totalPages;
    this.pageSize = 25;
    if (this.tableInfo != null) {
      this.allowEdit = false;
      this.adminService.$myAccess.subscribe(myAccess => {
        if (myAccess != null) {
          this.myAccess = myAccess;
          this.allowEdit = false;
          var dataSetAccess = this.myAccess.dataSets.find(d => d.dataSetId == this.tableInfo.id)
          if (dataSetAccess != null && (dataSetAccess.create || dataSetAccess.delete || dataSetAccess.update)) {
            this.allowEdit = true;
          }
        }
      });

      var serviceTable = this.tableService.selectedTable;
      //or check it here
      var table: any = {};
      Object.assign(table, serviceTable);
      if (typeof Worker !== 'undefined') {
        // Create a new
        const worker = new Worker(new URL('./worker.worker', import.meta.url));
        worker.onmessage = ({ data }) => {
          this.schema = table.schema;
          this.tabHeaders = this.buildHeaders(table.schema, table.id);
          this.tabRows = data;
          this.redraw();
          this.drawn = true;
          worker.terminate();
        };
        var obj = JSON.parse(JSON.stringify(serviceTable));
        worker.postMessage(obj);
      } else {
        // Web workers are not supported in this environment.
        // You should add a fallback so that your program still executes correctly.
      }
    } else {
      this.displayService.setSelectedSpanText("");
    }
  }

  // draws the table and sets up row/cell/column/table stylizations
  private drawTable(): void {
    if (this.tab != null) {
      this.tab.remove();
      this.tab = null;
    }
    this.tab = document.createElement("div");
    const callback = this.editRow.bind(this);
    var tableId = this.tableId;
    this.table = new Tabulator(this.tab, {
      rowFormatter: this.rowStateFormatter,
      cellClick: function (_e, cell) {
        if (!cell.valueAtLoad) {
          if (cell._cell.value === null) {
            cell._cell.value = "";
          }
          cell.valueAtLoad = cell._cell.value;
        }
      },
      cellEdited: function (cell) { //track whenever a cell is edited
        var row = cell._cell.row;
        var columnName = cell.getColumn()._column.field;
        var rowState: Number = row.data["rowState"];

        //new Value is different from original value;
        if (cell._cell.value != cell._cell.initialValue) {
          if (!row.changedCells) {
            row.changedCells = [];
            for (let cell of row.cells) {
              if (cell.value != cell.initialValue) {
              }
            }
          }
          row.changedCells.push(columnName);
        }
        //new Value is same as original value;
        else {
          if (row.changedCells) {
            if (row.changedCells.find(f => f == columnName)) {
              row.changedCells.pop(columnName);
            }
          }
        }

        switch (rowState) {
          case 0:
          case 1:
            if (row.changedCells.length >= 1) { //If row has changes
              if (row.data["rowState"] != 2) { //if row has changes and is original row
                row.data["rowState"] = 3;
                row.getElement().style.backgroundColor = "#A6A6DF";
              }
            }
            break;
          case 2:
            break;
          case 3:
            if (row.changedCells.length == 0) { //If row has changes
              if (row.data["rowState"] != 2) { //if row has changes and is original row
                row.data["rowState"] = 1;
                row.getElement().style.backgroundColor = "";
              }
            }
            break;
          case 4:
            break;
          case 5:
            break;
        }
        callback(row, tableId);
      },
      layout: "fitDataStretch",
      movableColumns: true,
      height: "100%",
      data: this.tabRows,
      columns: this.tabHeaders,
      locale: this.stringLocalizerService.currentCulture,
      langs: this.tabulatorLangObject,
      rowSelected: (row) => {
        this.tableService.addRowChange(row);
        this.updateRowChangesLength();
      },
      rowDeselected: (row) => {
        this.tableService.removeRowById(row._row.data.id);
        this.updateRowChangesLength();
      },
    });
    this.updateFilters();
    document.getElementById(`${this.tableId}`).appendChild(this.tab);
    this.displayService.setSelectedSpanText(this.schema.alias);
  }

  redraw() {
    this.drawTable();
  }

  // Deals with get rowSelection to work correctly 
  createAndAppendCheckbox(cell, onRendered) {
    let checkbox = this.createCheckbox("headerCheckbox");
    onRendered(() => {
      cell.getElement().appendChild(checkbox);
    });
    checkbox.addEventListener("change", this.handleCheckboxChange.bind(this));
  }

  createCheckbox(id: string) {
    let checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.id = id;
    return checkbox;
  }

  handleCheckboxChange(e) {
    const rows = this.table.getRows();
    if (e.target.checked) {
      rows.forEach(rowComponent => this.tableService.addRowChange(rowComponent));
      this.table.selectRow();
    } else {
      rows.forEach(rowComponent => this.tableService.removeRowById(rowComponent._row.data.id));
      this.table.deselectRow();
    }
  }

  // changes the color of a row if one of its cells were edited, and different from the original value at load
  rowStateFormatter = function (row) {
    var data = row.getData();
    if (data.rowState == 2) {
      row.getElement().style.backgroundColor = "#B8FFCC";
    }
    if (data.rowState == 3) {
      row.getElement().style.backgroundColor = "#A6A6DF";
    }
    if (data.rowState == 4) {
      row.getElement().style.backgroundColor = "#FFBAAB";
    }
    if (data.rowState == 5) {
      row.getElement().style.backgroundColor = "#FF8FD2";
    }
  }

  //creates headers and sort/editing type for cells under the given header.
  buildHeaders(schema: TableSchema, tableId: string): any[] {
    var headers: any[] = [];
    headers.push({
      formatter: "rowSelection",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      titleFormatter: (cell, formatterParams, onRendered) => {
        this.createAndAppendCheckbox(cell, onRendered);
      },
      titleFormatterParams: {
        rowRange: "active"
      }

    });
    schema.columns.forEach(c => {
      var h: any = {};
      h.title = c.alias;
      h.field = c.columnName;
      h.headerFilter = true;
      switch (c.columnDataType) {

        case 1: { //string
          h.headerFilter = "input";
          h.accessorDownload = this.nullToEmptyString;
          h.editable = this.allowEdit;
          h.headerFilterFunc = (headerValue, rowValue, rowData, filterParams) => {
            const isInUse = String(rowValue).toLowerCase().includes(String(headerValue).toLowerCase());
            this.filterStateService.updateFilterState(h.field, headerValue);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
            return isInUse;
          };
          h.headerFilterEmptyCheck = (value) => {
            const isEmpty = value === "";
            this.filterStateService.updateFilterState(h.field, isEmpty ? null : value);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
          };
          h.headerClick = (e, column, cell, headerValue) => {
            setTimeout(() => {
              this.sorterFunction();
              this.filterIsActive = this.checkIfAnyFilterIsActive();
            }, 0);
          };
          break;
        }
        case 2: { //integer
          h.accessorDownload = this.nullToEmptyString;
          h.editable = this.allowEdit;
          h.headerFilterFunc = (headerValue, rowValue, rowData, filterParams) => {
            const isInUse = String(rowValue).includes(headerValue);
            this.filterStateService.updateFilterState(h.field, headerValue);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
            return isInUse;
          };
          h.headerClick = (e, column, cell, headerValue) => {
            setTimeout(() => {
              this.sorterFunction();
              this.filterIsActive = this.checkIfAnyFilterIsActive();
            }, 0);
          };
          h.headerFilterEmptyCheck = (value) => {
            const isEmpty = value === "";
            this.filterStateService.updateFilterState(h.field, isEmpty ? null : value);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
          };
          break;
        }
        case 3: { //decimal
          h.accessorDownload = this.nullToEmptyString;
          h.editable = this.allowEdit;
          h.headerFilterFunc = (headerValue, rowValue, rowData, filterParams) => {
            const isInUse = String(rowValue).includes(headerValue);
            this.filterStateService.updateFilterState(h.field, headerValue);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
            return isInUse;
          };
          h.headerClick = (e, column, cell, headerValue) => {
            setTimeout(() => {
              this.sorterFunction();
              this.filterIsActive = this.checkIfAnyFilterIsActive();
            }, 0);
          };
          h.headerFilterEmptyCheck = (value) => {
            const isEmpty = value === "";
            this.filterStateService.updateFilterState(h.field, isEmpty ? null : value);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
          };
          break;
        }
        case 4: { //boolean
          h.headerFilter = "tickCross";
          h.headerFilterParams = { "tristate": true };
          h.editable = false;
          h.headerFilterFunc = (headerValue, rowValue, rowData, filterParams) => {
            if (headerValue === null || headerValue === "") {
              return true;
            }
            const isInUse = String(rowValue).includes(headerValue);
            this.filterStateService.updateFilterState(h.field, headerValue);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
            return isInUse;
          };
          h.headerClick = (e, column, cell, headerValue) => {
            setTimeout(() => {
              this.sorterFunction();
              this.filterIsActive = this.checkIfAnyFilterIsActive();
            }, 0);
          };
          h.headerFilterEmptyCheck = (value) => {
            const isEmpty = value === "";
            this.filterStateService.updateFilterState(h.field, isEmpty ? null : value);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
          };
          break;
        }
        case 5: { //date
          h.headerFilter = "input";
          h.headerFilterFunc = this.dateHeaderFilterFunction;
          h.headerFilterLiveFilter = "true";
          h.sorter = "date";
          h.editable = this.allowEdit;
          h.headerFilterFunc = (headerValue, rowValue, rowData, filterParams) => {
            const isInUse = String(rowValue).includes(headerValue);
            this.filterStateService.updateFilterState(h.field, headerValue);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
            return isInUse;
          };
          h.headerClick = (e, column, cell, headerValue) => {
            setTimeout(() => {
              this.sorterFunction();
              this.filterIsActive = this.checkIfAnyFilterIsActive();
            }, 0);
          };
          h.headerFilterEmptyCheck = (value) => {
            const isEmpty = value === "";
            this.filterStateService.updateFilterState(h.field, isEmpty ? null : value);
            this.filterIsActive = this.checkIfAnyFilterIsActive();
          };
          break;
        }
      }
      const dataSetAccess = this.tenantService.myAccess.dataSets.find(dataSet => dataSet.dataSetId === tableId);
      // sets what buttons are shown base on permission
      this.addRowShow = dataSetAccess.create;
      this.duplicateSelectedRowsShow = dataSetAccess.create;
      this.deleteSelectedRowsShow = dataSetAccess.delete;
      this.undeleteSelectedRowsShow = dataSetAccess.delete;
      this.editSelectedRowsShow = dataSetAccess.update;
      var tableEdit = this.allowEdit
      if (dataSetAccess.update == true || dataSetAccess.create == true || dataSetAccess.delete == true) {
        this.saveShow = true;
      } else {
        this.saveShow = false;
      }
      if (dataSetAccess?.update || dataSetAccess?.create) {
        //set editors
        if (c.listItems.length > 0) {
          h.editor = this.selectEditor;
          h.editorParams = { values: c.listItems };
        } else {
          switch (c.columnDataType) {
            case 1: { //string
              h.editor = "input";
              h.headerFilter = "input";
              break;
            }
            case 2: { //integer
              h.editor = "number";
              h.editorParams =
              {
                step: 1
              };
              h.formatter = this.integerFormatter;
              break;
            }
            case 3: { //decimal
              h.editor = "number";
              h.editorParams = {
                step: 1 / Math.pow(10, c.decimals)
              };
              h.headerFilter = "input";

              break;
            }
            case 4: { //boolean
              h.editable = false;
              h.formatter = "tickCross";
              h.hozAlign = "center";

              h.formatterParams = {
                allowTruthy: true,
              },
                h.cellClick = function (e, cell,) {
                  if (tableEdit) {
                    cell.setValue(!cell.getValue());
                  }
                }
              break;
            }
            case 5: { //date
              h.editor = this.dateEditorV2;
              h.formatter = this.dateFormatter;
              h.accessorDownload = this.downloadDate;
              break;
            }

            default: {
              break;
            }
          }
        }
      } else if (dataSetAccess?.read) {
        if (c.listItems.length <= 0) {
          switch (c.columnDataType) {
            case 4: { //boolean
              h.formatter = "tickCross";
              h.hozAlign = "center";
              h.formatterParams = {
                allowTruthy: true,
              }
              break;
            }
            case 5: { //date
              h.formatter = this.dateFormatter;
              h.accessorDownload = this.downloadDate
              break;
            }

            default: {
              break;
            }
          }
        }
      } else {
      }

      //set sorters
      h.headerSortTristate = true;
      switch (c.columnDataType) {
        case 1: { //string
          h.sorter = "string";
          break;
        }
        case 2: { //integer
          h.sorter = "number";
          break;
        }
        case 3: { //decimal
          h.sorter = "number";
          break;
        }
        case 4: { //boolean
          h.sorter = "boolean";
          break;
        }
        case 5: { //date
          h.sorter = this.dateSorter;
          break;
        }
        default: {
          break;
        }
      }
      headers.push(h);
    });
    return headers;
  }

  //renders Invalid Request if decimal is used in integer
  integerFormatter(cell, formatterParams, onRendered) {
    var cellValue = cell.getValue();

    // Check if cellValue is undefined and set it to null
    if (cellValue === undefined) {
      cellValue = null;
    }

    if (cellValue % 1 === 0) {
      cell.getElement().style.color = "#000000";
    } else {
      cell.getElement().style.color = "#FF0000";
      return cellValue = "INVALID INTEGER";
    }
    return cell.getValue(); //return the contents of the cell;
  }

downloadCsv() {
  this.tableService.downloadFile(this.tableInfo.id, this.tableInfo.alias, this.filterParams, this.stringLocalizerService.currentCulture).then(blob => {
    const a = document.createElement('a');
    const objectUrl = URL.createObjectURL(blob);
    a.href = objectUrl;
    a.download = `${this.tableInfo.alias}.csv`;
    a.click();
    URL.revokeObjectURL(objectUrl);
  }).catch(error => {
    console.error('Error downloading CSV:', error);
  });
}

  //When downloading turns null values into empty strings
  nullToEmptyString = function (value, data, type, params, column) {
    return value || ""; //return the new value for the cell data.
  }

  // when downloading changes the date to common format.
  downloadDate = function (value, field, data, type, params, column) {
    if (value == null || value == "" || value == "(mm/dd/yyyy)") {
      return value || "";
    } else {
    }
  }
  updateRowStateCounts() {
    // Assuming this.tabRows contains all rows and their current state
    const counts = { edited: 0, new: 0, deleted: 0 };
    this.tableService.myTableChanges.editedRows.rows.currentChanges.forEach(row => {
      switch (row.rowState) {
        case 3: counts.edited++; break;
        case 2: counts.new++; break;
        case 4:
        case 5: counts.deleted++; break;
      }
    });
    this.rowStateCounts.next(counts);
  }

  updateRowChangesLength(): void {

    this.tableService.rowChanges.subscribe(changes => {
      this.$rowChangesLength.next(changes.length);
    });
  }

  dateHeaderFilterFunction = function (headerValue, rowValue, rowData, filterParams) {
    //headerValue - the value of the header filter element
    //rowValue - the value of the column in this row
    //rowData - the data for the row being filtered
    //filterParams - params object passed to the headerFilterFuncParams property
    if (rowValue) {
      if (headerValue != "") {
        let newHeader = headerValue.replaceAll(/[-_.]/g, "/");
        let simpleRowValue = moment(rowValue, "YYYY-MM-DDTHH:mm:ss");
        var reformattedMomentValue = simpleRowValue.format("MM/DD/YYYY");
        if (reformattedMomentValue.includes(newHeader)) {
          let filterRowValue = rowValue;
          return filterRowValue;
        }
      }
    }
    return this.filterRowValue;
  }

  selectEditor = function (cell, onRendered, success, cancel, editorParams) {
    // Create and style editor
    const editor = document.createElement("select");
    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";
    if (editorParams && Array.isArray(editorParams.values)) {
      editorParams.values.forEach(value => {
        const option = document.createElement("option");
        option.value = value;
        option.text = value;
        editor.appendChild(option);
      });
    } else {
      console.error('editorParams.values is undefined or not an array');
    }
    const cellValue = cell.getValue();
    if (cellValue != null) {
      editor.value = cellValue;
    }
    onRendered(function () {
      editor.focus();
      editor.style.height = "100%";
    });
    editor.addEventListener("change", function () {
      success(editor.value);
    });
    editor.addEventListener("blur", function () {
      cancel();
    });
    return editor;
  };

  dateEditorV2 = function (cell, onRendered, success, cancel, editorParams) {
    //cell - the cell component for the editable cell
    //onRendered - function to call when the editor has been rendered
    //success - function to call to pass the successfuly updated value to Tabulator
    //cancel - function to call to abort the edit and return to a normal cell
    //editorParams - params object passed into the editorParams column definition property

    //create and style editor
    var editor = document.createElement("input");

    editor.setAttribute("type", "input");

    //create and style input
    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    //Set value of editor to the current value of the cell
    var getValue = cell.getValue();
    if (getValue == null || getValue == "" || getValue == "INVALID DATE") {
      editor.value = "(mm/dd/yyyy)";
      editor.setSelectionRange(0, editor.value.length);
    }
    else {
      var editorValue = moment(getValue, "YYYY-MM-DDTHH:mm:ss").format("MM/DD/YYYY");
      editor.value = editorValue;
    }

    //set focus on the select box when the editor is selected (timeout allows for editor to be added to DOM)
    onRendered(function () {
      editor.focus();
      editor.style.height = "100%";
    });

    //when the value has been set, trigger the cell to update
    function successFunc() {
      var value = editor.value;
      if (value == null || value == "(mm/dd/yyyy)" || value == "") {
        success(null);
      }
      else {
        var testMoment = moment(value, "MM-DD-YYYY");
        var formatMoment = testMoment.format("YYYY-MM-DDTHH:mm:ss");
        if (formatMoment == "Invalid date") {
          cell.getElement().style.color = "#cc3300";
          success("INVALID DATE");
        } else {
          cell.getElement().style.color = "";
          success(formatMoment);
        }
      }
    }

    editor.addEventListener("blur", successFunc);
    editor.addEventListener("keydown", function (e) {
      if (e.key == "Enter") {
        successFunc();
      }

      if (e.key == "Escape") {
        cancel();
      }
    });

    //return the editor element
    return editor;
  };

  dateFormatter = function (cell, formatterParams, onRendered) {
    var value = cell.getValue();
    if (value == null || value == "" || value == "(mm/dd/yyyy)") {
      var style = cell.getElement().style;
      cell.getElement().style.color = "#e0e0eb"
      return "(mm/dd/yyyy)";
    }
    else {
      var momentValue = moment(value, "YYYY-MM-DDTHH:mm:ss");
      var reformattedMomentValue = momentValue.format("MM/DD/YYYY");
      if (reformattedMomentValue != "Invalid date") {
        return reformattedMomentValue;
      }
      else {
        return "INVALID DATE";
      }
    }
  };

  dateSorter = function (a, b, aRow, bRow, column, dir, sorterParams) {
    //a, b - the two values being compared
    //aRow, bRow - the row components for the values being compared (useful if you need to access additional fields in the row data for the sort)
    //column - the column component for the column being sorted
    //dir - the direction of the sort ("asc" or "desc")
    //sorterParams - sorterParams object from column definition array
    var calcDate = function (date) {
      var momentValue = moment(date, "YYYY-MM-DDTHH:mm:ss");
      if (momentValue.isValid()) {
        var shortened = date.split("T")[0].replaceAll("-", "");
        var shortenedNumber = parseInt(shortened);
        return shortenedNumber;
      } else {
        if (date == null || date == "") {
          return 0;
        } else {
          return -1;
        }
      }
    };

    var aNumber = calcDate(a);
    var bNumber = calcDate(b);

    return aNumber - bNumber; //you must return the difference between the two values
  };


  // adds an empty row to the table, and assigns any specified default values to their respective cells.
  addRow() {
    var newRow = {};
    this.schema.columns.forEach(c => {
      if (c.columnDataType == 4) {
        newRow[c.columnName] = Parser.parseBoolean(c.defaultValue);
      } else {
        newRow[c.columnName] = c.defaultValue
      }
    });
    newRow["id"] = this.generateUUID();
    // newRows didn't have an index so delete could getIndex to delete.
    newRow["index"] = newRow["id"];
    newRow["rowState"] = 2;
    this.tableService.addTableRow(newRow, this.tableInfo["id"]);
    this.updateFilters();
    this.table.addRow(newRow).then((row) => {
      this.table.deselectRow(row._row.data.id);
    });
    this.updateRowStateCounts();
  }

  // creates a copy of any selected rows, assigns those rows new ID's, and adds those rows to the table
  duplicateSelectedRows() {
    var rows = this.table.getSelectedData();
    if (rows.length > 0) {
      rows.forEach(r => {
        if (r.rowState != 4 && r.rowState != 5) {
          var newRow = {};
          this.schema.columns.forEach(c => {
            newRow[c.columnName] = r[c.columnName];
          });
          newRow["id"] = this.generateUUID();
          newRow["index"] = newRow["id"];
          newRow["rowState"] = 2;
          let id = this.tableInfo.id;
          this.tableService.addCopyTableRow(newRow, id);
          this.updateFilters();
        }
        this.table.addRow(newRow)
      });
    } else {
      this.tableService.rowChanges.value.forEach(row => {
        var newRow = {};
        this.schema.columns.forEach(c => {
          newRow[c.columnName] = row[c.columnName];
        });
        newRow["id"] = this.generateUUID();
        newRow["index"] = newRow["id"];
        newRow["rowState"] = 2;
        let id = this.tableInfo.id;
        this.tableService.addCopyTableRow(newRow, id);
        this.updateFilters();

        const rowData = row._row.data;

        // Iterate over the keys of rowData
        Object.keys(rowData).forEach(key => {
          // Check if the key is not one of the excluded ones
          if (!['rowState', 'index', 'id'].includes(key)) {
            // Copy the value to newRow
            newRow[key] = rowData[key];
          }
        });
        this.table.addRow(newRow).then((row) => {
          this.table.deselectRow(row.getData().id); // Deselect the newly added row
        });
        this.tableService.addRowToRowChangesToCopy(row, this.tableInfo["id"]);
      });
    }
    this.updateRowStateCounts();
  }

  getRowChangesToCopy(rowx) {
    let row = rowx._row.data;
    if (row.rowState != 4 && row.rowState != 5) {
      var newRow = {};
      this.schema.columns.forEach(c => {
        newRow[c.columnName] = row[c.columnName];
      });
      newRow["id"] = this.generateUUID();
      newRow["index"] = newRow["id"];
      newRow["rowState"] = 2;
      this.table.addRow(newRow);
      this.tableService.addTableRow(newRow, this.tableInfo["id"]);
      this.updateFilters();
    }
    this.updateRowStateCounts();
  }

  deleteSelectedRows() {
    this.showDeleted = false;
    this.showNewDeleted = false;
    let selectedRows = this.table.getSelectedRows();
    let rowChanges = this.tableService.rowChanges.getValue();
    rowChanges.forEach(rowChange => {
      this.tableService.myTableChanges.editRow(rowChange._row.data.id, rowChange._row.data);
    });
    // Existing logic to process selected rows
    selectedRows.forEach(row => {
      this.tableService.myTableChanges.editRow(row.getData().id, row.getData());
    });
    this.tableService.myTableChanges.editedRows.rows.currentChanges.forEach((r, key, map) => {
      let isRNotInDontDelete = rowChanges.some(rowComponent => rowComponent._row.data.id === r.id);
      if (isRNotInDontDelete) {
        switch (r["rowState"]) {
          case 0:
          case 1:
          case 3:
            r["rowState"] = 4;
            this.updateFiltersAndScrollToRow(r);
            r.getElem
            break;
          case 2:
            r["rowState"] = 5;
            this.updateFiltersAndScrollToRow(r);
            break;
        }
      }
    });
    this.updateFilters();
  }
  updateFiltersAndScrollToRow(r) {
    this.updateFilters();
    let rowToUpdate = this.table.getRow(r.id);
    if (rowToUpdate) {
      rowToUpdate.rowState = r.rowState;
      rowToUpdate.getElement().style.backgroundColor = r.rowState == 4 ? "#FFBAAB" : "#FF8FD2";
      this.table.deselectRow(rowToUpdate);
    }
    this.tableService.removeRowById(r.id);
    this.updateRowStateCounts();
  }
  undeleteSelectedRows() {
    let changes = this.tableService.myTableChanges;
    changes.editedRows.rows.previousChanges.forEach((r, key) => {
      if (r.rowState == 5) {
        //Change "New Deleted" to "New"
        r.rowState = 2;
        this.table.deselectRow(r.id);
      } else if (r.rowState == 4) {
        var changedCells = [];
        var initialValues = this.tableService.getInitialValues(r.id, this.tableInfo.id);
        for (let key in r) {
          let value = r[key];
          if (r != "index" && !key.startsWith("_") && value != "rowState") {
            if (initialValues && initialValues.id != r.id) {
              changedCells.push(value);
            }
          }
        }
        if (changedCells.length >= 1) {
          r.changedCells = changedCells;
        }
        if (r.changedCells && r.changedCells.length >= 1) {
          r.rowState = 3;
        } else {
          r.rowState = 1;
        }
      }
      changes.editedRows.rows.set(key, r);
      let existingRow = this.table.getRow(r.id);
      if (existingRow) {
        existingRow.update(r);
      }
      if (r.rowState == 1) {
        let row = this.table.getRow(r.id);
        if (row) {
          row._row.data.rowState = 1;
          row.getElement().style.backgroundColor = "";
        }
      }
      if (r.rowState == 2) {
        let row = this.table.getRow(r.id);
        if (row) {
          row._row.data.rowState = 2;
        }
      }
      if (r.rowState == 3) {
        let row = this.table.getRow(r.id);
        if (row) {
          row._row.data.rowState = 3;
        }
      }
      this.table.deselectRow(r.id);
    });
    this.updateFilters();
    this.updateRowStateCounts();
  }

  private generateUUID(): string { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  public editRow(rowData: any, tableId: string) {
    this.tableService.editRow(rowData, this.tableInfo["id"], this.currentPage);
    this.updateRowStateCounts();
  }

  public async saveTableChanges() {
    let selectedRows = this.table.getSelectedRows();
    if (selectedRows) {
      selectedRows.forEach(row => {
        const rowData = row.getData();
        this.tableService.myTableChanges.editRow(rowData.id, rowData);
      });
    }
    var rows = this.table.getRows()
      .filter(r => r._row.data.rowState >= 2 && r._row.data.rowState <= 5);
    await this.tableService.updateTableRows(this.tableInfo.id).then(success => {
      if (success) {
        var deletedIndexes: string[] = [];
        var upsertedIndexes: string[] = [];
        rows.forEach(row => {
          switch (row._row.data["rowState"]) {
            case 2:
            case 3:
              upsertedIndexes.push(row.getIndex());
              break;
            case 4:
            case 5:
              deletedIndexes.push(row.getIndex());
              break;
          }
        });
        this.commitUpsertedRows(upsertedIndexes);
        this.commitDeletedRows(deletedIndexes);

        // Resetting the data for counter-container
        this.rowStateCounts.next({ edited: 0, new: 0, deleted: 0 });
        this.$rowChangesLength.next(0);

        this.toastService.showSuccessToast('', 'Saving Complete!');
      } else {
        console.error("failed to save data");
        this.toastService.showErrorToast('Save Failed', '');
      }
    })
      .catch(error => {
        console.error(error, "failed to save data");
        this.toastService.showErrorToast('Save Failed', '')
      });
  }

  private commitUpsertedRows(newIndexes: string[]) {
    newIndexes.forEach(index => {
      var row = this.table.getRow(index);
      if (row) {
        row._row.data["rowState"] = 1;
        row.getElement().style.backgroundColor = "";
        row._row.changedCells = [];
      }
    });
  }

  private commitDeletedRows(deletedIndexes: string[]) {
    deletedIndexes.forEach(index => {
      this.table.deleteRow(index);
    });
  }

  // FILTER CONTROLS

  // toggle visibility of unedited, non-new records
  public toggleOriginalRecordsFilter() {
    this.showOriginal = !this.showOriginal;
    this.showOriginalToolTipKey = this.showOriginal ? "hideOriginalRecords" : "showOriginalRecords";
    this.updateFilters();
  }

  public toggleEditedOriginalRecordsFilter() {
    if (this.tableService.myTableChanges && this.tableService.myTableChanges.editedRows) {
      this.tableService.myTableChanges.editedRows.rows.currentChanges.forEach((value, key) => {
        if (value.rowState === 3) {
          var row = this.table.getRow(value.id);
          if (this.table.getRow(value.id)) {
            let rowChanges = this.tableService.rowChanges.getValue();
            for (let rowChange of rowChanges) {
              let dataId = rowChange._row.data.id;
              let rowId = value.id;
              if (dataId === rowId) {
                row.select();
              }
            }
          } else {
            this.table.addData(value);
          }
          let rowChanges = this.tableService.rowChanges.getValue();

          for (let rowChange of rowChanges) {
            let dataId = rowChange._row.data.id;
            let rowId = value.id;
            if (dataId === rowId) {
              if (row != false) {
                row.select();
              }
            }
          }
        }
      });
    }
    this.showChangedToolTipKey = this.showChanged ? "hideEditedOriginalRecords" : "showEditedOriginalRecords";
    this.showChanged = !this.showChanged;
    this.updateFilters();
  }

  // toggle visibility of newly created records
  public toggleNewRecordsFilter() {
    this.showNew = !this.showNew;
    this.showNewToolTipKey = this.showNew ? "hideNewRecords" : "showNewRecords";
    this.updateFilters();
  }

  // toggle visibility of deleted, non-new records
  public toggleDeletedRecordsFilter() {
    if (this.tableService.myTableChanges && this.tableService.myTableChanges.editedRows) {
      this.tableService.myTableChanges.editedRows.rows.currentChanges.forEach((value, key) => {
        if (value.rowState === 5 || value.rowState === 4) {
          var row = this.table.getRow(value.id);
          if (!row) {
            this.table.addData(value);
          }
        }
      });
    }
    this.showDeleted = !this.showDeleted;
    this.showNewDeleted = !this.showNewDeleted;
    this.showDeletedToolTipKey = this.showDeleted ? "hideDeletedRecords" : "showDeletedRecords";
    this.updateFilters();
  }
  public toggleSelectedRecordsFilter() {
    if (this.showSelectedRecords === false) {
      this.tableService.rowChanges.value.forEach(value => {
        var row = this.table.getRow(value._row.data.id);
        if (!row) {
          this.table.addData(value._row.data);
          this.table.selectRow(value._row.data.id);
          this.showSelectedRecords = true;

        }
      });
    } else {
      const selectedRows = this.table.getSelectedRows();
      selectedRows.forEach(row => {
        this.table.deleteRow(row.getData().id);
      });
      this.showSelectedRecords = false;
    }
    // Toggle the state
    this.showNewDeletedToolTipKey = this.showSelectedRecords ? "hideDeletedNewRecords" : "showDeletedNewRecords";
    this.updateFilters();
  }

  // triggers table filters to go into effect
  private updateFilters() {
    var rowStateFilter = [];
    if (this.showOriginal) { rowStateFilter.push(0); rowStateFilter.push(1) }
    if (this.showNew) { rowStateFilter.push(2) }
    if (this.showChanged) { rowStateFilter.push(3) }
    if (this.showDeleted) { rowStateFilter.push(4) }
    if (this.showNewDeleted) { rowStateFilter.push(5) }
    if (rowStateFilter.length == 0) { rowStateFilter.push(-1) }
    this.table.setFilter("rowState", "in", rowStateFilter);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  nextPage() {
    this.currentPage++;
    this.showSelectedRecords = false;
    this.tableService.loadTablePage(this.tableInfo.id, this.currentPage, this.pageSize, null, null)
      .then(data => {
        this.table.setData(data.rows);
      });
  }

  prevPage() {
    if (this.currentPage > 1) {
      this.showSelectedRecords = false;
      this.currentPage--;
      this.tableService.loadTablePage(this.tableInfo.id, this.currentPage, this.pageSize, null, null)
        .then(data => {
          this.table.setData(data.rows);
        });
    }
  }

  // Add this method to your component
  updatePageSize(newSize: number) {
    this.currentPage = 1;
    this.pageSize = newSize;
    this.showSelectedRecords = false;
    this.getPageRange();
    this.tableService.loadTablePage(this.tableInfo.id, this.currentPage, this.pageSize, this.filterParams, this.sorter)
      .then(data => {
        this.table.setData(data.rows);

        let flatRowChanges = this.tableService.rowChanges.value.flat();
        this.table.getRows().forEach(row => {
          let rowId = row._row.data.id;
          if (flatRowChanges.some(change => change._row.data.id === rowId)) {
            row.select();
          }
        });
      });
  }

  sorterFunction() {
    this.filterStateService.updateSorterState(true);
    let sortParams = [];
    let currentSorters = this.table.getSorters();
    this.showSelectedRecords = false;
    if (currentSorters) {
      currentSorters.forEach(sorter => {
        if (sorter.dir === "desc") {
          sortParams.push(`${sorter.field} d`);
        } else if (sorter.dir === "asc") {
          sortParams.push(`${sorter.field} a`);
        }
      });
    }


    let sortParam = sortParams.join(";");
    this.sorter = sortParam;
    this.tableService.loadTablePage(this.tableInfo.id, this.currentPage, this.pageSize, this.filterParams, this.sorter)
      .then(data => {
        const transformedData = data.rows;
        let flatRowChanges = this.tableService.rowChanges.value.flat();
        this.table.setData(transformedData);
        this.table.getRows().forEach(row => {
          let rowId = row._row.data.id;
          if (flatRowChanges.some(change => change._row.data.id === rowId)) {
            row.select();
          }
        });
      });
  }
  searchAll() {
    let columns = this.table.getColumnDefinitions();
    let filters = [];
    let currentSorters = this.table.getSorters();
    let sortParams = [];
    this.showSelectedRecords = false;
    for (let column of columns) {
      let filterValue = this.table.getHeaderFilterValue(column.field);
      if (column.headerFilter == "tickCross") {
        if (this.headerFilterValues.some(e => e.name === column.field)) {
          filters.push(`${column.field} eq ${this.headerFilterValues.find(e => e.name === column.field).value}`);
        }
      }
      let sorter = currentSorters.find(s => s.field === column.field);
      if (filterValue) {
        filters.push(`${column.field} lk ${filterValue}`);
      }
      if (sorter) {
        if (sorter.dir === "desc") {
          sortParams.push(`${column.field} d`);
        } else if (sorter.dir === "asc") {
          sortParams.push(`${column.field} a`);
        }
      }
    }
    let sortParam = sortParams.join(";");
    let filterParam = filters.join(";");
    this.filterParams = filterParam;
    this.loadPage(1);
    this.tableService.loadTablePage(this.tableInfo.id, 1, this.pageSize, filterParam, sortParam)
      .then(data => {
        this.table.setData(data.rows);
        let flattenedRowChanges = this.tableService.rowChanges.value.flat();
        this.table.getRows().forEach(row => {
          let rowId = row.getData().id;
          if (flattenedRowChanges.some(change => change && change._row && change._row.data.id === rowId)) {
            row.select();
          }
        });
      })
  }

  clearFilters() {
    this.table.clearHeaderFilter();
    this.table.clearSort();
    this.headerFilterValues = [];
    this.filterIsActive = false;
    this.filterStateService.updateSorterState(false);
    this.filterStateService.clearFilterState();
    this.filterParams = null;
    this.sorter = null;
    this.showSelectedRecords = false;
    this.tableService.loadTablePage(this.tableInfo.id, this.currentPage, this.pageSize, null, null)
      .then(data => {
        this.table.setData(data.rows);
        let flattenedRowChanges = this.tableService.rowChanges.value.flat();
        this.table.getRows().forEach(row => {
          let rowId = row.getData().id;
          if (flattenedRowChanges.some(change => change._row.data.id === rowId)) {
            row.select();
          }
        });
      });
  }

  getPageRange() {
    this.totalPages = this.tableService.totalPages
    let start = Math.max(this.currentPage - 2, 1);
    let end = Math.min(start + 4, this.totalPages);
    start = Math.max(end - 4, 1); // Recalculate start in case end is less than start + 4
    let range = [];
    for (let i = start; i <= end; i++) {
      range.push(i);
    }
    return range;
  }

  loadPage(page: number) {
    this.currentPage = page;
    this.showSelectedRecords = false;
    this.tableService.loadTablePage(this.tableInfo.id, this.currentPage, this.pageSize, this.filterParams, this.sorter)
      .then(data => {
        if (data) {
          this.table.setData(data.rows);
          if (this.tableService.rowChanges && this.tableService.rowChanges.value) {
            let currentRowChanges = this.tableService.rowChanges.getValue();
            let flattenedRowChanges = this.tableService.rowChanges.value.flat();
            this.table.getRows().forEach(row => {
              let rowId = row.getData().id;
              if (flattenedRowChanges.some(change => change._row.data.id === rowId)) {
                row.select();
              }
            });
          }
        } else {
          console.error('Data is undefined');
        }
      });
  }
}